<template>
  <div id="root" @click="goHome()">
    <div class="header" >
      <img src="../assets/logo.png" />
      <div class="title">清单自习室</div>
      <div style="flex: 1"></div>
      <el-button type="primary" round @click="goLogin()">去登录</el-button>
    </div>
    <img  class="app_ad_img" src="../assets/1.jpg" />
    <div class="bottom">
      <div class="company">Copyright © 广州商周科技有限公司</div>
      <el-link href="https://beian.miit.gov.cn" target="_blank">粤ICP备19037992号</el-link>
    </div>
  </div>
</template>

<script>

import storage from '@/store/storage'

export default {
  components: {
  },
  created () {
    if (storage.get('user') != null) {
      this.$router.push('/todolist')
    }
  },
  methods: {
    goLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
#root {
}
.header{
  display: flex;
  padding: 1rem 3rem;
  align-items: center;
  -moz-box-shadow:2px 2px 5px #eeeeee; -webkit-box-shadow:2px 2px 5px #eeeeee; box-shadow:2px 2px 5px #eeeeee;

}
.header img{
  width: 3rem;
}
.title{
  font-size: 1.25rem;
  color: #333333;
  margin-left: 1rem;
}
.app_ad_img{
  width: 20rem;
  margin-left: 10rem;
  margin-top: 2rem;
}
.bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  color: #666666;
  margin-top: 0.5rem;
}
.company{
  margin-right: 0.5rem;
}
</style>
